<template>
    <span class="uppercase"><slot></slot></span>
</template>

<script>
export default{}
</script>

<style>
    .uppercase{ text-transform: uppercase; }
</style>